@import '_variables';

@import '~bootstrap/scss/bootstrap';

:root {
  --toastify-color-info: var($blue);
  --toastify-color-success: var($green);
  --toastify-color-warning: var($yellow);
  --toastify-color-error: var($red);
}

.navbar {
  background-color: $kite-blue;
}

.ck-editor__editable {
  max-height: 100px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.nav-link-text {
  color: #c9deff !important;
}

.profile-link-text {
  color: $kite-blue !important;
}

.link-text {
  color: $kite-blue !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.navbar-nav li:hover > ul.dropdown-menu {
  display: block;
}

.hidden-popup {
  display: none;
}

.k-searchbar {
  width: 100% !important;
}

.modal-input {
  max-width: 1300px !important;
}


// Kendo Footer Styling for Tableview Numbers
.k-pager-numbers .k-button:not(.k-button-icon) .k-button-text {
  color: $kite-blue !important;
}

// Kendo Footer Styling for Tableview Arrows
.k-pager-nav {
  color: $kite-blue !important;
}

// Kendo Button Styling for Reporting Change Log Calendar Icons
.k-icon k-svg-icon k-svg-i-calendar k-button-icon {
  fill: white !important;
}

#bootstrap-white-text {
  color: white !important;
}

#bootstrap-white-text:disabled {
  color: #ced4da !important;
}

input,
select {
  max-height: 40px;
  margin-left: 4px;
}

label {
  margin-top: 2px;
}

.white-modal-header {
  color: white;
}

.modal-body-spacing {
  padding: 10px;
}

.bootstrap-modal-footer-button-alignment {
  text-align: right;
  margin-left: 5px;
  float: 'right';
}

.bootstrap-footer-button-stylization {
  width: 200px;
  margin-right: 5px;
  margin: '2px';
  float: 'right';
}

.bootstrap-footer-button-stylization-center {
  width: 200px;
  text-align: center;
}


.k-upload .k-dropzone {
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
  padding: 50px 8px;
  background-color: #fff;
  border: 2px dashed grey;
  border-radius: 5px;
}
.text-right {
  text-align: right;
}
.p-10 {
  padding: 10px;
}
.k-button.k-state-hover:not(.k-button-icon),
.k-button:hover:not(.k-button-icon),
.k-chip,
.k-button:not(.k-button-icon) {
  border-color: rgba(0, 0, 0, 0.08);
  color: #fff;
  background-color: #1560b7;
  background-image: linear-gradient(#4273c6, #1560b7);
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
}
.m-10 {
  margin: 10px;
}
.end-xs {
  text-align: end;
}

legend {
  font-size: large;
  color: $kite-blue;
}

// giving space to the page content
.page-container {
  margin-top: calc(var(--header-height));
}

.locked-header {
  // position: sticky;
  top: calc(var(--header-height) - 1px);
  background-color: $gray-300;
  z-index: 4;
  border-top: solid 1px $gray-500;
  border-bottom: solid 1px $gray-500;
}

.mw-100 {
  min-width: 100%;
}
.b-ruby {
  display: block ruby;
  display: -webkit-inline-box;
}

// OVERRIDES BOOTSTRAP MARGINS THAT SPACE OUT PROJECT STEUP TABS
.accordion-button {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.accordion-header {
  position: relative;
}

// Help window styling
.help-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $kite-blue;
  color: $white;
}
.help-wrapper {
  position: absolute;
  height: 54.4px;
  right: 60px;
  top: 0;
  text-align: right;
  z-index: 3; // Solves flickering issue
}
.help-button {
  all: unset;
  color: white;
  line-height: 54.4px;
  font-size: 14pt;
}
.userProfile {
  margin-top: 50px;
  height: 430px;
  border: 1px solid #ccc;
  margin-right: 15px;
}
.single-dr {
  padding-left: 10px;
  vertical-align: middle;
}

.Login {
  .k-textbox {
    max-width: 100%;
    width: 100%;
  }
}

#forgotPassword {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 15px;
}

// TABLEVIEW GRIDS CSS
.tableviewTabs {
  padding-top: 20px;
}

strong {
  font-weight: bold;
}

.removeSubject {
  display: inline-block;
  color: $red;
  top: 12px;
  margin-left: 100px;
  font-size: 20px;
}

.newEditProjectHeaderContainer {
  background-color: rgb(21, 96, 183);
  z-index: 99;
  width: 100%;
  padding-left: 1rem;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  font-size: 24px;
  color: white;
  font-weight: 600;
}

// ADDED SIDE PADDING FOR SPECIFIC PAGES
.locked-header {
  padding: 1rem;
}

.tableview-tabs {
  padding: 1rem;
}

#map-upload-container {
  padding: 1rem;
}

// QUICK FIX: We should update HTML to place this on a different row so we don't need to pad this.
#download-templates-button {
  vertical-align:middle;
}

.commit-page-container {
  padding: 1rem;
}

.branch-list-page-container {
  padding: 1rem;
}

// .k-numerictextbox .k-input {
//   height: 10px !important; //Could be set at any height
// }

////////////////////////
// REPORTING PAGE CSS //
////////////////////////
.reporting-container {
  padding-left: 0;
  padding-right: 0;
  margin-left: 12px;
}

.reporting-section {
  margin-top: 12px;
  margin-bottom: 12px;
}

.reporting-header {
  font-size: 28px;
  color: $kite-blue;
  font-weight: 700;
}

.reporting-instructions {
  padding-bottom: 5px;
}

.reporting-intro-text {
  font-size: 18px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.reporting-input-section {
  background-color: #e9ecef;
  padding: 20px;
  width: 98vw;
  margin-left: 0px;
  border-radius: 5px;
  padding-bottom: 25px;
}

.reporting-input-menus-container {
  padding-bottom: 5px;
}

.reporting-results-container {
  margin-left: 20px;
  margin-right: 20px;
}

.selected-report-header {
  font-size: 26px;
  color: $kite-blue;
  font-weight: 700;
  margin-bottom: 12px;
}

.histogram-counter {
  height: 2.2rem;
  width: 20rem;
  border: none !important;
}

.reporting-date-picker {
  width: 90% !important;
  height: 37px;
  background-color: white;
}

.reporting-option-header {
  font-size: 20px;
  color: $kite-blue;
  font-weight: 500;
  margin-left: 5px;
}

.reporting-map-selection-dropdown {
  margin-left: 0 !important;
}

.autosuggest-form-wrapper {
  position: relative;
  // display: inline-block;
  // box-shadow: 0px 8px 20px rgb(0 0 0 / 6%);
}

.autosuggest-input {
  height: 37px;
  padding-left: 15px;
  border-radius: 5px;
  font-size: 17px;
  border: none;
}

.autosuggest-input {
  outline: none;
}

.autosuggest-suggestion-wrapper {
  z-index: 1 !important;
  overflow-y: scroll;
  position: absolute;
  top: 37px;
  left: 0;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  // padding: 10px 15px;
  max-height: 250px;
  width: 90%;
  text-align: start;
  box-shadow: 0px 8px 20px rgb(0 0 0 / 6%);
}

.autosuggest-suggestions {
  width: 100%;
  padding: 8px 5px;
  border-bottom: 1px solid #e5e5e5;
}

.autosuggest-suggestions:hover {
  background-color: $kite-blue;
  font-weight: 500;
  color: white;
  cursor: pointer;
}

.generate-report-button-container {
  margin-top: 20px;
}

.generate-report-button {
  font-size: 18px !important;
  width: 100%;
}

.map-options-fine-print-container {
  margin-top: 30px;
}

.map-options-fine-print {
  font-size: 16px;
}

.react-autosuggest__suggestions-container--open {
  z-index: 999;
}

.generated-report-header {
  font-size: 40px;
  font-weight: 700;
  color: $kite-blue;
  margin-left: 15px;
}

.generated-report-subhead {
  font-size: 20px;
  font-weight: 700;
  color: black;
  margin-left: 15px;
}

.generated-report-button-container {
  margin-left: 15px;
  margin-top: 10px;
}

.k-listview-content {
  overflow: hidden !important;
}

.change-log-modal {
  max-width: none !important;
  margin-top: 50px !important;
  margin-bottom: 100px !important;
  padding-right: 0 !important;
  width: 90% !important;
  overflow-y: hidden;
}

#log-changes-added {
  display: inline-block;
  height: 24px;
  // background-color: #20c997;
  background-color: #40b61834;
  font-weight: 600;
  padding: 0 2px;
}

#log-changes-removed {
  display: inline-block;
  height: 24px;
  background-color: #ff741833;
  font-weight: 600;
  // background-color: red;
  padding: 0 2px;
}

.download-report-button {
  border-color: rgba(0, 0, 0, 0.08);
  color: #fff;
  background-color: $purple;
  background-image: linear-gradient($cyan, $purple);
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 600;
  width: 25%;
}

///////////////
// FOCUS CSS //
///////////////
.accordion-button:focus {
  z-index: 3;
  border-color: #ff7518 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #ff7518 !important;
}

input:focus {
  border: 2px solid #ff7518 !important;
}

.ck-focused {
  outline: 2px solid #ff7518 !important;
}

.help-button:focus {
  color: #ff7518 !important;
}

.k-button:focus {
  outline: 3px solid #ff7518 !important;
}

.btn-success:focus {
  outline: 3px solid #ff7518 !important;
}

////////////////////////////////////
// CSS ADDED TO ACCOMODATE UPDATE //
////////////////////////////////////

.k-chip-label {
  color: white;
}

.k-chip-actions {
  color: #dee2e6;
}

.buttons {
  display: -webkit-flex;
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: flex-start;
}

// TOAST MESSAGE STYLIZATION

.Toastify__toast {
  display: flex;
  align-items: center;
}

.Toastify__toast-body {
  flex: 1;
  margin-right: 10px; /* Adjust as needed */
}

.Toastify__close-button {
  width: auto; /* Adjust as needed */
}