.kite-background {
  height: calc(100vh - var(--header-height));
  width: 100%;
  background-image: url('./LM-BG.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.card ul.nav li {
  font-size: 1.1em;
}
