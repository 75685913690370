.color-pallette-container {
    position: fixed;
    width: 210px;
    height: 145px;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    filter: drop-shadow(0px 0px 2px);
}

.color-block {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 5px;
    border: 1px solid #adb5bd;
    cursor: pointer;
}