@import '../../styles/base/variables';

.kite_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $white;
  z-index: 999;

  &__inner {
    padding: 0.5rem 0;
  }
}
