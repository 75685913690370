@import '../../styles/base/variables';

///////////////////////////
// GOJS-CANVAS COMPONENT //
///////////////////////////
///

.diagram-component {
  z-index: 1;
  height: 67vh;
  width: 100%;
  // background: radial-gradient(white 60%, rgb(191, 191, 191));
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.empty-canvas {
  z-index: 1;
  height: 67vh;
  width: 100%;
  background: radial-gradient(white 60%, rgb(191, 191, 191));
  -webkit-tap-highlight-color: transparent;
}

.load-message {
  margin-top: 32vh;
  text-align: center;
  color: $kite-blue;
  font-weight: 500;
}

////////////////////
// PAGE CONTAINER //
////////////////////

.canvas-page-container {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

.canvas-section-container {
  max-height: 100vh;
}

.gsfBackground {
  border-color: #b9c6fb;
  font-size: 10px;
  margin-bottom: 7px;
  background: radial-gradient(white 60%, rgb(191, 191, 191));
}

///////////////
// REVIEW CSS //
////////////////

.review-mode-background {
  border-color: #b9c6fb;
  font-size: 10px;
  margin-bottom: 7px;
  background: radial-gradient(rgb(224,238,255) 60%, rgb(224,238,255));
}

.delete-text {
  color: red;
}

.add-text {
  color: $kite-blue
}

.edit-text {
  color: #3fb618;
}

.no-change-text {
  color: black;
}
///////////////
// INTERFACE //
///////////////

.learning-map-interface-container {
  z-index: 99;
  position: relative;
  width: 100%;
  left: 0;
}

.learning-map-interface {
  background-color: $gray-300;
  top: 0;
  left: -12px;
  width: 100%;
  filter: drop-shadow(0px 0px 3px);
  height: 140px;
}

////////////////////////////////
// GENERAL SUBMENU STYIZATION //
////////////////////////////////
.k-animation-container {
  z-index: 2000 !important;
}

.disabled-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(128, 128, 128, 0); /* Translucent grey */
  z-index: 100;
  // display: none; /* Hidden by default */
}

.submenu-container {
  position: fixed;
  z-index: 98;
  top: 18.5rem;
  width: 65rem;
  background-color: white;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
  left: 50%;
  padding-bottom: 12px;
}

.submenu-top-bar-blue {
  background-color: $kite-blue;
  height: 30px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  height: 40px;
}

.submenu-close-x {
  position: absolute;
  cursor: pointer;
  font-size: 21px;
  right: 0;
  top: 0;
  margin-right: 4px;
  color: $gray-100;
  z-index: 999;
}

.white-menu-header {
  color: white;
  text-align: center;
  border-radius: 8px;
  font-size: 24px;
}

.instructional-blue {
  background-color: #E0EEFF;
  font-size: 16px;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 8px;
}

.comment-button-stylization {
  width: 100px;
  margin-right: 5px;
  margin: '2px';
  float: 'right';
}

// AUTOSUGGEST CONTENT //
.autosuggest-filter-input {
  height: 25px !important;
  margin-bottom: 5px !important;
  line-height: 1.5 !important;
  padding-left: 15px;
  border-radius: 2px !important;
  font-size: 16px;
  outline: 1px solid rgb(145, 145, 145) !important;
}

.react-autosuggest__container {
  position: relative;
}

.autosuggest-suggestions {
  width: 100%;
  padding: 8px 5px;
  border-bottom: 1px solid #e5e5e5;
}

.autosuggest-suggestions:hover {
  background-color: $kite-blue;
  font-weight: 500;
  color: white;
  cursor:pointer;
}

.react-autosuggest__input {
  width: 240px;
  height: 26px;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  // font-family: Helvetica, sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #707070;
  border-radius: 2px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 28px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
/////////////////////

.pre-filter-auto-complete {
  position: relative;
  border: 1px solid transparent;
  background-color: #f1f1f1 !important;
  padding: 10px;
  font-size: 16px;
  // z-index: 97;
}

.pre-filter-auto-complete-container {
  position: relative;
  z-index: 99;
}

.pre-filter-instructions{
  padding: 12px;
  font-size: 16px;
  // text-align: center;
  // background-color: #E0EEFF;
  color: black;
}

.pre-filter-button-container {
  margin-top: 20px !important;
  margin-bottom: 10px !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.pre-filter-button-enabled {
  font-size: 20px;
  color: white;
  height: 50px;
  width: 150px;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
}

.pre-filter-button-disabled {
  font-size: 20px;
  color: white;
  height: 50px;
  width: 100px;
  background-color: $gray-600;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
}

.pre-filter-footer {
  background-color: #E0EEFF;
  position: relative;
  text-align: center;
  padding: 12px;
  font-size: 18px;
  color: $red;
  font-weight: bold;
  border-radius: 0 0 8px 8px;
}

// PREFILTER LAPTOP SIZES
// @media screen and (max-width: 1440px) {
//   .pre-filter-container {
//     position: fixed;
//     z-index: 98;
//     top: 19rem;
//     margin-left: 5rem;
//     margin-right: 5rem;
  
//     justify-content: center;
//     background-color: white;
//     border-radius: 8px;
//     transition: 0.5s ease-in-out;
//     box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
//   }

//   .pre-filter-header {
//     color: white;
//     background-color: $kite-blue;
//     font-size: 18px;
//     font-weight: bold;
//     width: 100%;
//     text-align: center;
//     padding: 12px;
//     border-radius: 8px 8px 0 0;
//   }

//   .pre-filter-warning-instruction-block {
//     font-size: 16px;
//     padding-top: 6px;
//     background-color: #E0EEFF;
//     color: $red;
//     text-align: center;
//     font-weight: bold;
//   }
  
//   .pre-filter-instruction-block {
//     font-size: 16px;
//     background-color: #E0EEFF;
//     color: black;
//     text-align: center;
//   }
  
//   .pre-filter-option-instruction {
//     position: relative;
//     font-size: 16px;
//     color: black;
//     padding-right: 50px;
//     // text-align: center;
//   }
  
//   .pre-filter-field-titles{
//     // margin-bottom: 12px;
  
//     font-size: 18px;
//     margin-left: 2rem;
//     padding-bottom: 12px;
//     font-weight: bold;
//     text-align: left;
//     // background-color: #E0EEFF;
//     color: black;
//   }

//   .pre-filter-footer {
//     background-color: #E0EEFF;
//     position: relative;
//     text-align: center;
//     padding: 12px;
//     font-size: 16px;
//     color: $red;
//     font-weight: bold;
//     border-radius: 0 0 8px 8px;
//   }
// }

// @media screen and (max-width: 1024px) {
//   .pre-filter-option-instruction {
//     position: relative;
//     font-size: 14px;
//     color: black;
//     padding-right: 0px;
//     // text-align: center;
//   }

//   .pre-filter-auto-complete {
//     // text-align: center;
//     position: relative;
//     border: 1px solid transparent;
//     background-color: #f1f1f1;
//     padding: 10px;
//     margin-right: 20px;
//     font-size: 16px;
//     z-index: 1000;
//   }

//   .pre-filter-warning-instruction-block {
//     font-size: 14px;
//     padding-top: 6px;
//     background-color: #E0EEFF;
//     color: $red;
//     text-align: center;
//     font-weight: bold;
//   }
  
//   .pre-filter-instruction-block {
//     font-size: 14px;
//     background-color: #E0EEFF;
//     color: black;
//     text-align: center;
//   }
  
//   .pre-filter-option-instruction {
//     position: relative;
//     font-size: 14px;
//     color: black;
//     padding-right: 0px;
//     // text-align: center;
//   }

//   .pre-filter-button-enabled {
//     font-size: 16px;
//     color: white;
//     height: 30px;
//     width: 75px;
//     background-color: $purple;
//     border-radius: 5px;
//     border: none;
//     filter: drop-shadow(0px 0px 1px);
//   }
  
//   .pre-filter-button-disabled {
//     font-size: 16px;
//     color: white;
//     height: 25px;
//     width: 75px;
//     background-color: $gray-600;
//     border-radius: 5px;
//     border: none;
//     filter: drop-shadow(0px 0px 1px);
//   }
// }


/////////////////////////////////////
// PROFILE INFO SECTION (FAR LEFT) //
/////////////////////////////////////

.profile-container {
  position: relative;
  right: 0;
  padding-top: 12px;
  padding-left: 20px;
}

.profile-section {
  display: inline-block;
}

#profile-middle-section {
  margin-top: 5px;
}

.profile-header {
  display: inline;
  font-size: 16px;
  color: $kite-blue;
  font-weight: 700;
}

.profile-item {
  display: inline;
  margin-left: 5px;
}

.profile-buttons {
  display: inline;
  margin-left: 5px;
}

.viewSaveLoadButtons {
  height: 50px;
  width: 70px;
  font-size: small;
  padding: 1px;
  border-radius: 50%;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
}

.viewSaveLoadButtons:disabled {
  pointer-events: none;
  opacity: 0.65;
}

///////////////////////////////////////
// EDITING TOOLSBAR BUTTONS (CENTER) //
///////////////////////////////////////

.editing-button-toolset {
  position: relative;
  text-align: center;
  margin: 5px;
  left: 0;
  // vertical-align: middle;
  top: 50%;
  transform: translateY(-50%);
}

// RED DATA EDITING BUTTON
.hard-editing-button {
  font-size: 20px;
  height: 50px;
  width: 50px;
  color: $gray-300;
  background-color: $red;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

// BLUE BUTTONS
.soft-editing-button {
  font-size: 20px;
  height: 50px;
  width: 50px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin: 1px;
  cursor: pointer;
}

.soft-editing-button-open {
  font-size: 20px;
  height: 50px;
  width: 50px;
  color: $gray-300;
  background-color: #2780e3;
  border-radius: 5px;
  border: none;
  margin: 1px;
  border: 3px solid #ff7518;
}

.soft-editing-button:disabled {
  color: $gray-200;
  background-color: #6697d0;
  cursor:auto;
}

.image-download-button {
  height: 50px;
  width: 50px;
  font-size: 15px;
  padding: 2px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

.image-download-button-open {
  height: 50px;
  width: 50px;
  font-size: 15px;
  padding: 2px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

.image-download-button:disabled {
  height: 50px;
  width: 50px;
  font-size: 15px;
  padding: 2px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

// VIEW SAVE/LOAD BUTTONS
.view-save-load-buttons {
  height: 50px;
  width: 102px;
  font-size: 15px;
  padding: 2px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

.view-save-load-buttons-open {
  border: 3px solid #ff7518;
  background-color: #2780e3;
  height: 50px;
  width: 102px;
  font-size: 15px;
  padding: 2px;
  color: $gray-300;
  border-radius: 5px;
  margin: 1px;
}

.view-save-load-buttons:disabled {
  height: 50px;
  width: 102px;
  font-size: 15px;
  padding: 2px;
  border-radius: 50%;
  color: $gray-200;
  background-color: #6697d0;
  border-radius: 5px;
  border: none;
  margin: 1px;
}

.save-load {
  height: 40px;
  width: 80px;
  font-size: 16px;
  color: $gray-300;
  background-color: $kite-blue;
  border-radius: 8px;
  border: none;
  margin: 3px;
  filter: drop-shadow(0px 0px 1px);
}

.primary-button:hover {
  cursor: pointer;
}

.view-save-modal-header {
  color: white;
}

.view-save-modal-body {
  margin-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  margin-bottom: 40px;
}

// FILTER SUGGESTION DROPDOWN

.react-autosuggest__suggestions-list {
  max-height: 200px;
  overflow-y: auto;
}

/* VIEW OPTION BUTTONS */

////////////////////////////////////
// VIEW OPTION BUTTONS (FAR RIGHT) //
////////////////////////////////////

.view-button-toolset {
  position: relative;
  text-align: right;
  right: 0;
  margin: 5px;
  margin-right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.view-change-button {
  height: 50px;
  width: 50px;
  color: $white;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  margin-left: 1px;
  margin-bottom: 1px;
}

.view-change-button-open {
  height: 50px;
  width: 50px;
  color: $white;
  background-color: $cyan;
  border-radius: 5px;
  border: none;
  margin-left: 1px;
  margin-bottom: 1px;
  border: 3px solid #ff7518;
}

.view-change-button:focus {
  border: 3px solid #ff7518;
  // background-color: $cyan;
}

.view-change-button:disabled {
  height: 50px;
  width: 50px;
  color: $white;
  background-color: #6697d0;
  border-radius: 5px;
  border: none;
  margin-left: 1px;
  margin-bottom: 1px;
}

.neighborhood-legend-button {
  height: 50px;
  width: 254px;
  font-size: 14px;
  color: $white;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  margin-top: 2px;
  filter: drop-shadow(0px 0px 1px);
}

.neighborhood-legend-button:disabled {
  height: 50px;
  width: 254px;
  font-size: 14px;
  color: $white;
  background-color: #6697d0;
  border-radius: 5px;
  border: none;
  margin-top: 2px;
  filter: drop-shadow(0px 0px 1px);
}

.neighborhood-legend-button-open {
  height: 50px;
  width: 254px;
  font-size: 14px;
  color: $white;
  background-color: $cyan;
  border: 3px solid #ff7518;
  border-radius: 5px;
  margin-top: 2px;
  filter: drop-shadow(0px 0px 1px);
}

#view-menu-drag-arrows {
  position: absolute;
  margin-left: 5px;
  margin-top: 5px;
  text-align: left;
}

#LayerLongestPathSource {
  transform: rotate(90deg);
}

#LayerLongestPathSink {
  transform: rotate(-90deg);
}

#delete {
  color: $red;
}

.global-sidebar-button-container {
  position: absolute; /* Position the button absolutely within the topbar */
  left: 20px; /* Adjust the left margin as needed */
  bottom: -65px; /* Adjust the bottom margin as needed */
  padding: 10px; /* Add padding for better appearance */
  border: none; /* Remove borders if desired */
  cursor: pointer; /* Add cursor style for interaction */
  border-radius: 5px;
  z-index: -1000 !important;
  height: 50px;
  width: 50px;
  transition: 0.5s ease-in-out;
}

.global-sidebar-button-closed {
  background-color: $kite-blue; /* Example background color for the button */
  color: #fff; /* Example text color for the button */
}

.global-sidebar-button-open {
  background-color: $blue; /* Example background color for the button */
  color: #fff; /* Example text color for the button */
}


//////////////////////
// DATA EDITOR MENU //
//////////////////////

.data-editor-subheader {
  font-size: 16px;
  color: black;
  font-weight: 500;
}

.data-editor-section {
 margin-top: 5px;
 margin-bottom: 15px; 
}

.data-editor-description-input {
  line-height: 24px !important;
  width: 100%;
  // border-style: none; 
  outline: 1px solid;
  border-color: Transparent; 
  overflow: auto;        
  margin-left: 5px;
  // max-height: "300px";
  // max-width: "100%";
  // padding: 6px;
  // margin-left: 3px;
}

.close-data-editor-menu {
  opacity: 0;
  transform: translate(-50%, -30.5rem);
  pointer-events:none;
}

.open-data-editor-menu {
  opacity: 1;
  transform: translate(-50%, 1.5rem);
}

textarea {
  vertical-align: top;
}

.data-editor-grey-section {
  background-color: $gray-300
}

.data-editor-body-padding {
  padding-bottom: 25px;
  margin-left: 25px;
}

.bold-instruction {
  font-weight: 500;
}

/////////////////
// FILTER MENU //
/////////////////
.close-filter-menu {
  opacity: 0;
  transform: translate(-50%, -40.5rem);
  pointer-events: none;
}

.open-filter-menu {
  opacity: 1;
  transform: translate(-50%, 1.5rem);
}

/////////////////
/* THEME MENU */
/////////////////
.close-theme-menu {
  // display: none;
  opacity: 0;
  transform: translate(50%, -45.5rem);
  // top: -100px !important; 
}

.open-theme-menu {
  // display: block;
  opacity: 1;
  transform: translate(50%, 1.5rem);

  overflow: -moz-hidden-unscrollable;
}

.theme-menu-container {
  position: fixed;
  z-index: 98;
  right: 50%;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
  box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.5);
}

.theme-menu {
  position: relative;
  background: white;
  border-radius: 0 0 8px 8px;
  width: 1000px;
  height: 100%;
  padding: 1.5rem;
}

.theme-options-headers {
  color: $kite-blue;
  text-align: center;
  font-size: 17px;
  padding-bottom: 0.5rem;
}

.theme-stylization-section {
  background-color: #e9ecef;
  padding: 12px;
  border-radius: 8px;
}

.theme-font-section {
  margin-top: 1rem;
  background-color: #e9ecef;
  padding: 12px;
  border-radius: 8px;
}

.theme-preview-section {
  // margin-top: 1rem;
  background-color: #e9ecef;
  padding-top: 12px;
  border-radius: 8px;
}

.theme-section-header {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.theme-option {
  margin-top:6px;
  margin-bottom: 6px;
  display: inline-block;
}

.theme-select-all-checkbox {
  margin-top: 1rem;
}

.theme-select-all-label {
  padding-left: 5px;
  font-weight: bold;
}

#theme-select-all-override-explainer {
  margin-left: 0.5rem;
}

// #theme-select-all-override-explainer {
//   margin-left: 0.5rem;
// }


.theme-action-buttons {
  margin-top: 1rem;
  text-align: right;
}

.theme-apply-button {
  height: 35px;
  width: 183px;
  font-size: 14px;
  color: white;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
}

.theme-apply-button:focus {
  background-color: #2780e3;
  border: 3px solid #ff7518;
}

.theme-reset-button {
  height: 35px;
  width: 183px;
  font-size: 14px;
  color: white;
  background-color: $kite-blue;
  border-radius: 5px;
  border: none;
  margin-left: 1rem;
  filter: drop-shadow(0px 0px 1px);
}

.theme-reset-button:focus {
  background-color: #2780e3;
  border: 3px solid #ff7518;
}

.node-shape-dropdown {
  z-index: 99;
  background-color: white;
  position:fixed;
  border: 0.1px solid rgb(54, 54, 54);
  filter: drop-shadow(0px 0px 1px);
}

.node-shape-option {
  z-index: 99;
  padding-left: 5px;
  padding-right: 5px;
  background-color: white;
}

.node-shape-option:hover {
  color: white;
  background-color: $blue;
}

.pallette-visibility-on {
  z-index: 99;
  position:fixed;
  visibility:visible;
  background-color: white;
}

.pallette-visibility-off {
  position:absolute;
  visibility:hidden;
}

.theme-element-input {
  display: inline-block;
  width: 500px;
  padding-left: 1rem;
  padding-right: 1rem;
}

.default-color-button {
  background: linear-gradient(to right, #f0ad4e 0%, #f0ad4e 50%,#3fb618 50%, #3fb618 100%), linear-gradient(to right, #2780e3 0%, #2780e3 50%, #e83e8c 50%, #e83e8c 100%);
  background-size: 100% 50%;
  background-position: center top, center bottom;
  background-repeat: no-repeat;
  display: inline-block;
  margin-bottom: -2.5px;
  height: 18px;
  width: 18px;  
}

.default-shape-button {
  // background: linear-gradient(to right, #f0ad4e 0%, #f0ad4e 50%,#3fb618 50%, #3fb618 100%), linear-gradient(to right, #2780e3 0%, #2780e3 50%, #e83e8c 50%, #e83e8c 100%);
  // background-size: 100% 50%;
  // background-position: center top, center bottom;
  // background-repeat: no-repeat;
  display: inline-block;
  // margin-bottom: -2.5px;
  height: 18px;
  width: 18px;  
  text-align: center;
}
.selected-shape-button {
  // background: linear-gradient(to right, #f0ad4e 0%, #f0ad4e 50%,#3fb618 50%, #3fb618 100%), linear-gradient(to right, #2780e3 0%, #2780e3 50%, #e83e8c 50%, #e83e8c 100%);
  // background-size: 100% 50%;
  // background-position: center top, center bottom;
  // background-repeat: no-repeat;
  // display: inline-block;
  // margin-bottom: -2.5px;
  height: 18px;
  width: 18px;  
}

.selected-color-button {
  display: inline-block;
  margin-bottom: -2.5px;
  height: 18px;
  width: 18px;  
  margin-left: 12.5px;
}

.mini-diagram {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 22vh;
  border-radius: 5px;
  background-color: #e9ecef;
  // background: radial-gradient(white 60%, rgb(191, 191, 191));
  -webkit-tap-highlight-color: transparent;
}

.mini-canvas-wrapper {
  text-align: right; 
  // overflow: hidden;
}

.minicanvas-preview-form {
  text-align: center;
}

.minicanvas-preview-selector {
  margin-left: 7px;
  width: 150px !important;
  // display: inline-block;
}

.shape-form {
  width: 110px;
  display: inline-block;
}

#node-fill-color-button {
  margin-left: 17px;
}

#neighborhood-background-color-button {
  margin-left: 18px;
}

.border-width-form {
  margin-left: 4px;
  display: inline-block;
}

.border-width-selector {
  width: 35px;
}

.connection-width-form {
  margin-left: 4px;
  display: inline-block;
}

.connection-width-selector {
  width: 35px;
}

.arrow-style-form {
  margin-left: 4px;
  display: inline-block;
}

.arrow-style-selector {
  width: 85px;
  display: inline-block;
}

.font-style-form {
  width: 117px;
  // margin-left: 7px;
  display: inline-block;
}

.font-style-selector {
  
  display: inline-block;
}

.font-size-form {
  // margin-left: 7px;
  display: inline-block;
}

.font-size-selector {
  width: 85px;
  display: inline-block;
}

////////////////////////////
// NODE INSPECTOR SUBMENU //
////////////////////////////

.inspector-header {
  padding: 7px !important;
  text-align: center;
  color: white;
  font-size: 20px;
}

.inspector-id {
  padding: 7px;
  background-color: #E0EEFF;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

// #inspector-id {
//   background-color: #E0EEFF;
//   margin: 0 !important;
//   padding: 0 !important;
// }

.inspector-upper-details {
  padding-top: 12px;
  background-color: white;
}

.modal-header {
  display: block !important;
  background-color: $kite-blue !important;
}

.modal-header-text {
  color: white;
}

.modal-body {
  padding: 0 !important;
}

.close-inspector-menu {
  opacity: 0 !important;
  transform: translateY(0px) !important;
}

.open-inspector-menu {
  opacity: 1;
  transform: translateY(150px);
  box-shadow: 1px 1px 2px rgb(0, 0, 0, 0.5);
}

.inspector-data-field {
  position: relative;
  display: inline-block;
  text-align: left;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
}

.inspector-label {
  display: inline;
  font-size: 16px;
  color: $kite-blue;
  font-weight: 700;
}

.inspector-data {
  display: inline;
  font-size: 16px;
  color: black;
  font-weight: normal;
  margin-left: 8px;
}

.attribute-label {
  display: inline;
  font-size: 16px;
  color: black;
  font-weight: 700;
  margin-left: 0px;
}

.inspector-list {
  font-size: 18px;
}

#comment-grey {
  background-color: #DEE2E6 !important;
}

.modal-footer {
  background-color: #DEE2E6 !important;
}

.comments-header {
  font-size: 32px;
  font-weight: 600;
  color: $kite-blue;
  padding-left: 16px;
  margin-top:0;
  border-top: 1px solid lightgrey;
  padding-top:12px;
  padding-bottom: 12px;
}

.new-menu-header {
  background-color: $kite-blue !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
}

.card {
  border-radius: 8px !important;
}

//////////////////////////////
// TABLEVIEW REDIRECT MODAL //
//////////////////////////////

.tableview-redirect-container {
  margin-top: 12px;
  margin-bottom: 12px;
  background-color: white;
  width: 1200px;
}

.tableview-redirect-data-row {
  padding-top: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid grey;
}

.table-redirect-headers-container {
  margin-top: 6px;
}

.table-redirect-headers-column { 
  margin-left: 12px;
  font-size: 20px;
  font-weight: 600;
  color: $kite-blue;
}

.table-redirect-data {
  margin-left: 12px;
  margin-right: 12px;
  font-size: 14px;
  
}

//////////////////////////
// CANVAS TUTORIAL MENU //
//////////////////////////

.tutorial-menu-options {
  background-color: #E0EEFF
}

.tutorial-button {
  margin: 0;
  padding: 12px;
  padding: 12px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  box-shadow: 0px 0px 2px #000000;
  color: white;
  background-color: $kite-blue;
}

.canvas-tutorial-header {
  font-size: 32px;
  color: white;
}

.tutorial-instruction-header {
  padding: 6px;
}

.tutorial-instruction-section {
  background-color: #E0EEFF;
  padding: 12px;
}

.tutorial-step-section {
  padding-left: 12px;
}

.tutorial-step {
  font-size: 22px;
  color: $kite-blue;
  font-weight: 600;
}

.tutorial-carousel-padding {
  padding: 25px;
  border-bottom: 1px solid #868e96;
  border-right: 1px solid #868e96;
  box-shadow: 0px 0px 2px #000000;
}

///////////////////////////////
// VIEWS SAVE AND LOAD MENUS //
///////////////////////////////

.view-save-menu-container {
  position: fixed;
  z-index: 98;
  top: 140px;
  left: 50%;
  // MARGIN-L MUST BE HALF OF WIDTH IN FILTER MENU TO CENTER
  margin-left: -250px;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
}

.view-save-menu {
  position: fixed;
  color: $kite-blue;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  width: 500px;
  align-content: center;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
}

.view-save-header {
  color: $kite-blue;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.save-name-input-container {
  margin-top: 8px;
  text-align: left;
  margin-top: 8px;
}

.save-view-button-container {
  margin-top: 8px;
  text-align: left;
  margin-top: 8px;
}

.save-name-input-field {
  height: 25px;
  width: 100%;
  max-width: 100% !important;
  font-size: 14px;
  color: black;
  background-color: $gray-100;
  border-radius: 5px;
  border: gray;
  filter: drop-shadow(0px 0px 1px);
}

.save-view-button {
  height: 25px;
  width: 100%;
  font-size: 14px;
  color: white;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
}

.close-view-save-menu {
  opacity: 0 !important;
  transform: translateY(0px) !important;
}

.open-view-save-menu {
  opacity: 1;
  transform: translateY(150px);
  box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.5);
}

/////////////////////////////////
/* NEIGHBORHOOD LEGEND SUBMENU */
/////////////////////////////////

.neighborhood-legend-menu {
  z-index: 98;
  position: fixed;
  margin-right: 20px;
  border-radius: 15px 15px 15px 15px;
  transition: 0.5s ease-in-out;
}

.neighborhood-legend {
  border-radius: 15px 15px 15px 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: $purple;
  color: white;
  position: sticky;
  min-height: 80px;
}

.k-multiselect .k-multiselect-wrap .k-input ::placeholder {
  text-align: center !important;
  float: none !important;
}

input[type='text'] {
  max-width: 100% !important;
}

.legendCheckBoxContainer {
  padding-left: 10px;
}

.neighborhood-checkbox-label {
  padding-left: 10px;
  font-size: 14px;
}

.show-all-neighborhoods-label {
  font-size: 14px;
  padding-left: 10px;
  font-weight: 400;
}

.close-neighborhood-legend {
  opacity: 0;
  right: 0;
  transform: translateY(-650px);
}

.open-neighborhood-legend {
  opacity: 1;
  right: 0;
  transform: translateY(15px);
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
}

/* Toolbar */

.toolbar {
  z-index: 99;
  position: absolute;
  display: sticky;
  align-items: center;
  justify-content: center;
  margin: 75px 50px;
}

.tool-button {
  cursor: pointer;
  padding: 15px;
  color: white;
  background-color: rgb(21, 96, 183);
  border-top: 1px solid white;
}

.tool-button-blue {
  cursor: pointer;
  padding: 15px;
  color: white;
  background-color: rgb(21, 96, 183);
  border-top: 1px solid white;
}

.tool-button-grey {
  cursor: pointer;
  padding: 15px;
  color: black;
  background-color: white;
  border-top: 1px solid white;
}

p {
  font: 300 1rem Roboto, helvetica, sans-serif;
  color: #212121; /* Grey 900 */
}

// Inspector Menu Container

.inspector-menu-container {
  position: fixed;
  z-index: 98;
  top: 18.5rem;
  width: 75rem;
  background-color: white;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
  left: 50%;
  padding-bottom: 12px;
}