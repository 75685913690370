@import '../../styles/base/variables';

.kite_breadcrumb {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 5px 1.1rem;
  list-style: none;
  background: #e0eeff;
  border-bottom: 1px solid #90bae9;
}
