@import '../../styles/base/_variables.scss';

.errorWindowHeader {
  color: white;
  font-weight: bold;
}

.toastifyHeader {
  color: white;
  font-weight: bolder;
  text-decoration: underline;
}
