@import '../../../styles/base/variables';
/////////////////
/* FILTER MENU */
/////////////////
.filter-menu-container {
  position: fixed;
  z-index: 98;
  top: 18.5rem;
  width: 65rem;
  background-color: white;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
  left: 50%;
  padding-bottom: 12px;
}

.filter-menu {
  position: relative;
  padding: 12px;
}

.filter-menu-header {
  position: relative;
  color: white;
  text-align: center;
  border-radius: 8px;
  align-content: center;
  font-size: 20px;
  z-index: 99999 !important
}

.requiredField {
  color: $gray-100;
}

.filter-header {
  color: $kite-blue;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-bottom: 12px;
}

.filter-field-titles {
  color: black;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  // margin-left: 5px;
  margin-top: -8px !important;
  margin-bottom: 8px;
}

// GLOBAL FILTER

.global-filter-keyword-section {
  padding-bottom: 5px;
}

.global-filter-primary-title {
  color: $kite-blue;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  padding-bottom: 10px;
}

.global-filter-secondary-title {
  color: black;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
  padding-bottom: 10px;
}

.global-filter-checklist-instructions {
  color: black;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  margin-left: 15px;
}

.global-filter-checkbox {
  margin-left: 25px;
  margin-right: 5px;
}

.global-filter-input {
  height: 27px !important;
  width: 97%;
  margin-left: 25px;
  margin-right: 15px;
  margin-bottom: 12px !important;
  margin-top: 5px;
  line-height: 1.5 !important;
  padding: 3px;
  border-radius: 2px !important;
  font-size: 16px;
  outline: 1px solid rgb(145, 145, 145) !important;
}

.global-filter-properties {
  list-style-type: none;
  padding-left: 0rem !important;
}

.k-button-text {
  color: white;
}

.global-filter-advanced-properties-title {
  color: $kite-blue;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 15px;
  padding-right: 5px;
  padding-bottom: 15px;
  display: inline-block;
}

.global-filter-advanced-opener-button {
  cursor: pointer;
  display: inline;
  height: 30px;
  width: 30px;
  color: $purple !important;
}

.global-filter-checkbox-button {
  cursor: pointer;
  margin-right: 4px;
  display: inline;
}

.global-filter-checkbox-on {
  background-color: $blue;
  color: #E0EEFF;
  border-radius: 5px !important;
  height: 22px;
  width: 22px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.global-filter-checkbox-off {
  background-color: #E0EEFF;
  color: $blue;
  border-radius: 5px !important;
  height: 22px;
  width: 22px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.global-filter-highlight-checkbox-on {
  background-color: $blue;
  color: #ebdaab;
  border-radius: 5px !important;
  height: 22px;
  width: 22px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.global-filter-highlight-button {
  cursor: pointer;
  margin-right: 3px;
  display: inline-block;
}

.global-filter-inquery-button {
  cursor: pointer;
  display: inline-block;
  height: 22px;
  width: 22px;
  color: $purple !important;
}

.global-filter-sidebar-string {
  font-size: 16px;
  vertical-align: middle !important;
  display: inline-block;
  font-weight: bolder;
}

#sidebar-legend {
  color: black;
}

.global-sidebar-details-title {
  font-weight: 700 !important;
  font-size: 13px;
  color: $purple;
}

.global-sidebar-title-label {
  font-size: 14px;
  color: $indigo;
  display:"inline-block";
  color: "#613d7c";
  font-weight: 700 ;
  padding-right: 5px;
}

.global-sidebar-connection-source-label {
  color: $indigo;
  display:"inline-block";
  color: "#613d7c";
  font-weight: 700 ;
}

.global-sidebar-connection-destination-label {
  color: $indigo;
  display:"inline-block";
  color: "#3fb618";
  font-weight: 700 ;
}

.global-sidebar-details-content {
  font-size: 14px;
  display:"inline-block";
  color: black;
  font-weight: 400;
}

.global-sidebar-details-list {
  font-size: 14px;
  display:"inline-block";
  color: black;
  font-weight: 400;
  margin-left: 4px;
}

.global-sidebar-details-list-item {
  font-size: 14px;
  display:"inline-block";
  color: black;
  font-weight: 400;
  margin-left: -5px !important;
}

.global-sidebar-details-attribute-name {
  font-size: 14px;
  display:"inline-block";
  color: black;
  font-weight: 600;
  margin-left: -5px !important;
}

.global-sidebar-details-attribute-value {
  font-size: 14px;
  display:"inline-block";
  color: black;
  font-weight: 400;
  margin-left: 5px !important;
}

///////////////////////////
// GLOBAL FILTER SIDEBAR //
/////////////////////////// 
.global-sidebar-button {
  position: absolute; /* Position the button absolutely within the topbar */
  left: 20px; /* Adjust the left margin as needed */
  bottom: -65px; /* Adjust the bottom margin as needed */
  padding: 10px; /* Add padding for better appearance */
  border: none; /* Remove borders if desired */
  cursor: pointer; /* Add cursor style for interaction */
  border-radius: 5px;
  z-index: -1000 !important;
}

.global-sidebar-off {
  display: none;
}

.global-filter-sidebar-container {
  position: relative;
  z-index: 100;
  width: 100%;
  background-color: white;
  overflow-y: hidden;
  transition: 0.5s ease-in-out;
  padding-bottom: 12px;;
}

.close-global-sidebar {
  opacity: 0;
  transform: translate(-150%);
}

.open-global-sidebar {
  opacity: 1;
  transform: translate(0%);
}

@keyframes slide-in-right {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out-left {
  from {
      transform: translateX(0);
  }
  to {
      transform: translateX(-150%);
  }
}


// .global-sidebar-container {
//   // max-height: 5px !important;
//   max-height: 60vh !important;
//   // overflow-y: scroll;
//   overflow-y: hidden;
// }

.global-sidebar-header {
  background-color: $kite-blue;
  padding: 2px;
  position: relative;
  color: white;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

// GLOBAL SIDEBAR INPUT SECTION

.global-sidebar-keyword-input-section {
  background-color: rgb(224,238,255);
  padding-top: 10px;
  padding-left: 10px;
  width: 100%;
  max-height: 60px;
  position: relative;
  color: black;
  text-align: left;
  overflow-y:auto;
  overflow-x:hidden;
  font-size: 16px;
}

.sidebar-search-button {
  background-color: $kite-blue;
  color: white;
  border-radius: 5px;
  font-size: 12px;
  padding: 2px;
  margin-right: 6px;
  height: 30px;
  width: 30px;
}

.global-sidebar-keyword-input {
  z-index:-1;
  display:contents;
  color: black;
  max-height:fit-content;
  
  font-weight: bolder;
  
  padding-left: 6px;
  cursor: pointer;
}

// GLOBAL SIDEBAR CHECKBOX SECTION

.global-sidebar-checkbox-section {
  background-color: rgb(224,238,255);
  padding: 0;
  padding-top: 6px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 0px;
}

.global-sidebar-checkbox[disabled] {
  font-size: 16px;
  margin: 0;
  display: inline-block;
}

input[type=checkbox][disabled]{
  color: $indigo;
}

.global-sidebar-checkbox-label {
  font-size: 16px;
  margin: 0;
  display: inline-block;
  padding-left: 5px;
  margin-right: 10px;
  font-weight: 500;
}

.global-sidebar-reset-buton {
  height: 25px;
  font-size: 14px;
  color: white;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
  text-align: center;
}

// GLOBAL SIDEBAR DROPDOWN SECTION

.global-sidebar-dropdown-section {
  background-color: rgb(224,238,255);
  padding: 6px 6px 0px 6px;
  position: relative;
  color: black;
  text-align: left;
  font-size: 16px;
}

.filter-dropdowns {
  height: 26px;
  font-size: 16px;
  background: '#black';
  outline: '1px solid lightgray';
  position: 'relative';
  width: 100%;
  background-color: $kite-blue;
  color: white;
  // padding-left: 22px;
  text-align: center;
}

.global-filter-dropdown {
  height: 26px;
  font-size: 16px;
  background-color: $kite-blue;
  color: white;
  outline: '1px solid lightgray';
  position: 'relative';
  width: 295px;
  text-align: center;
}

.global-sidebar-stats-bar {
  // background-color: $indigo;
  // background-color: $kite-blue;
  background-color: rgb(224,238,255);
  padding: 0;
  font-weight: 500;
  position: relative;
  color: black;
  text-align: center;
  font-size: 16px;
  // height: 12vh;
}

.global-sidebar-legend-section {
  background-color: rgb(224,238,255);
  overflow: hidden;
  padding-left: 4px;
}

.global-sidebar-legend {
  font-size: 13px;
  
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px;
  display: block;
}

.global-sidebar-results-section {
  background-color: white;
  padding-left: 4px;
  position: inherit;
  height: 46vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.global-filter-legend-header {
  font-size: 18px;
  color: $indigo;
  font-weight: 600;
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
}

.global-sidebar-element {
  font-size: 12px;
  color: $kite-blue;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 4px;
  display: block;
}

.global-filter-sidebar-element {
  font-size: 16px;
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
}

.global-sidebar-open-details-container {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 5px;
  padding-right: 10px;
}

.global-sidebar-open-details {
  padding-bottom: 6px;
}

.filter-instructions {
  background-color: #E0EEFF;
  padding: 12px;
  font-size: 16px;
  text-align: center;
}

.filter-menu-selector {
  height: 28px;
  font-size: 16px;
  background: '#black';
  outline: '1px solid lightgray';
  position: 'relative';
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
  width: 30%;
}

.filter-menu-buttons-container {
  position: relative;
  text-align: right;
}

.filter-inputs {
  position: relative;
  font-size: 16px;
}

input[type='text'] {
  background-color: white;
  width: 100%;
}

input[type='submit'] {
  background-color: DodgerBlue;
  color: #fff;
}

.filter-auto-complete-container {
  position: relative;
  z-index: 99;
}

.filter-auto-complete {
  position: relative;
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
  // z-index: 1000
  text-align: center;
}

.filter-button {
  height: 40px;
  width: 100px;
  margin-top: 14px;
  margin-right: 6px;
  padding-bottom: 8px !important;
  color: white;
  background-color: $purple;
  border-radius: 5px;
  border: none;
  filter: drop-shadow(0px 0px 1px);
}

.filter-button:focus {
  background-color: $cyan !important;
}

.filter-button-enabled:focus {
  background-color: $cyan !important;
}

#filter-button-enabled {
  background-color: $purple;
}

#filter-button-disabled {
  background-color: $gray-600;
}

.filter-reset-button {
  height: 40px;
  width: 100px;
  margin-top: 14px;
  color: white;
  background-color: $red;
  border-radius: 5px;
  border: none;
  margin-right: 2px;
  filter: drop-shadow(0px 0px 1px);
}

.filter-reset-button:focus {
  background-color: #b75858;
}

.filter-reset-button-solo:focus {
  background-color: #b75858;
}

.filter-menu-container-reset-only {
  position: fixed;
  z-index: 98;

  top: 19rem;
  background-color: white;
  border-radius: 8px;
  transition: 0.5s ease-in-out;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
  left: 50%;
}

.filter-menu-reset-only {
  position: relative;
  color: white;
  background-color: white;
  padding: 0 12px 12px 12px;
  border-radius: 8px;
  align-content: center;
  box-shadow: 0px 0px 20px rgb(0, 0, 0, 0.5);
}

.filter-reset-button-solo {
  // margin-top: 8px;
  height: 50px;
  width: 150px;
  margin-top: 14px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 20px;
  color: white;
  background-color: $red;
  border-radius: 5px;
  border: none;
  margin-right: 2px;
  filter: drop-shadow(0px 0px 1px);
}

.global-sidebar-footer {
  color: white;
  background-color: $kite-blue;
  padding: 5px 0 0 0;
  font-weight: 500;
  position: relative;
  text-align: center;
  font-size: 16px;
  height: 40px;
}

.global-sidebar-footer-warning {
  color: white;
  background-color: $red;
  padding: 5px 0 0 0;
  font-weight: 500;
  position: relative;
  text-align: center;
  font-size: 16px;
  height: 40px;
}